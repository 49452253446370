export const coreIconKeys = ["arrow-left","arrow-right","car","check","circle-info","cog","copy","info-sign","menu-hamburger","minus","new-window","person-biking","person-walking","plus","question-sign","qrcode","remove","resize-horizontal","resize-vertical","screenshot","search","slash","triangle-bottom","triangle-top","unchecked","zoom-in"];

export default {
	"glyphicons": [
		"adjust",
		"alert",
		"apple",
		"asterisk",
		"baby-formula",
		"ban-circle",
		"barcode",
		"bed",
		"bell",
		"bishop",
		"blackboard",
		"book",
		"bookmark",
		"briefcase",
		"bullhorn",
		"calendar",
		"camera",
		"cd",
		"certificate",
		"check",
		"chevron-right",
		"cloud",
		"cog",
		"comment",
		"credit-card",
		"cutlery",
		"dashboard",
		"earphone",
		"education",
		"envelope",
		"exclamation-sign",
		"eye-open",
		"facetime-video",
		"film",
		"fire",
		"flag",
		"flash",
		"fullscreen",
		"gift",
		"glass",
		"globe",
		"grain",
		"headphones",
		"heart-empty",
		"heart",
		"home",
		"hourglass",
		"info-sign",
		"lamp",
		"leaf",
		"lock",
		"menu-hamburger",
		"minus-sign",
		"minus",
		"move",
		"music",
		"new-window",
		"ok-circle",
		"ok-sign",
		"pencil",
		"phone-alt",
		"phone",
		"picture",
		"piggy-bank",
		"plane",
		"plus-sign",
		"plus",
		"pushpin",
		"qrcode",
		"question-sign",
		"record",
		"refresh",
		"remove",
		"resize-horizontal",
		"resize-vertical",
		"road",
		"scale",
		"scissors",
		"screenshot",
		"search",
		"send",
		"shopping-cart",
		"star-empty",
		"star",
		"stop",
		"sunglasses",
		"thumbs-down",
		"thumbs-up",
		"time",
		"tint",
		"trash",
		"tree-conifer",
		"tree-deciduous",
		"triangle-bottom",
		"triangle-top",
		"unchecked",
		"upload",
		"user",
		"warning-sign",
		"wrench",
		"zoom-in"
	],
	"mdiconic": [
		"bicycle",
		"car",
		"clear",
		"share",
		"walk"
	],
	"osmi": [
		"accommodation_alpinehut",
		"accommodation_bed_and_breakfast",
		"accommodation_bed_and_breakfast2",
		"accommodation_camping",
		"accommodation_caravan_park",
		"accommodation_chalet",
		"accommodation_chalet2",
		"accommodation_hostel",
		"accommodation_hotel",
		"accommodation_hotel2",
		"accommodation_motel",
		"accommodation_shelter",
		"accommodation_shelter2",
		"accommodation_youth_hostel",
		"amenity_bench",
		"amenity_court",
		"amenity_firestation",
		"amenity_firestation2",
		"amenity_firestation3",
		"amenity_fountain",
		"amenity_fountain2",
		"amenity_library",
		"amenity_library2",
		"amenity_playground",
		"amenity_police",
		"amenity_police2",
		"amenity_post_box",
		"amenity_post_office",
		"amenity_prison",
		"amenity_public_building",
		"amenity_public_building2",
		"amenity_recycling",
		"amenity_survey_point",
		"amenity_telephone",
		"amenity_toilets",
		"amenity_toilets_disabled",
		"amenity_toilets_men",
		"amenity_toilets_women",
		"amenity_town_hall",
		"amenity_town_hall2",
		"amenity_waste_bin",
		"barrier_blocks",
		"barrier_bollard",
		"barrier_cattle_grid",
		"barrier_cycle_barrier",
		"barrier_entrance",
		"barrier_exit",
		"barrier_gate",
		"barrier_kissing_gate",
		"barrier_lift_gate",
		"barrier_steps",
		"barrier_stile",
		"barrier_toll_booth",
		"education_college",
		"education_college_vocational",
		"education_nursery",
		"education_nursery2",
		"education_nursery3",
		"education_school",
		"education_school_primary",
		"education_school_secondary",
		"education_university",
		"example_blank",
		"example_font",
		"food_bar",
		"food_biergarten",
		"food_cafe",
		"food_drinkingtap",
		"food_fastfood",
		"food_fastfood2",
		"food_fastfood_pizza",
		"food_ice_cream",
		"food_pizza",
		"food_pub",
		"food_restaurant",
		"health_dentist",
		"health_doctors",
		"health_doctors2",
		"health_hospital",
		"health_hospital_emergency",
		"health_hospital_emergency2",
		"health_opticians",
		"health_pharmacy",
		"health_pharmacy_dispensing",
		"health_veterinary",
		"landuse_coniferous",
		"landuse_coniferous_and_deciduous",
		"landuse_deciduous",
		"landuse_grass",
		"landuse_hills",
		"landuse_quary",
		"landuse_scrub",
		"landuse_swamp",
		"money_atm",
		"money_atm2",
		"money_bank",
		"money_bank2",
		"money_currency_exchange",
		"place_of_worship_bahai",
		"place_of_worship_bahai3",
		"place_of_worship_buddhist",
		"place_of_worship_buddhist3",
		"place_of_worship_christian",
		"place_of_worship_christian3",
		"place_of_worship_hindu",
		"place_of_worship_hindu3",
		"place_of_worship_islamic",
		"place_of_worship_islamic3",
		"place_of_worship_jain",
		"place_of_worship_jain3",
		"place_of_worship_jewish",
		"place_of_worship_jewish3",
		"place_of_worship_shinto",
		"place_of_worship_shinto3",
		"place_of_worship_sikh",
		"place_of_worship_sikh3",
		"place_of_worship_unknown",
		"place_of_worship_unknown3",
		"poi_boundary_administrative",
		"poi_cave",
		"poi_crane",
		"poi_embassy",
		"poi_embassy2",
		"poi_military_bunker",
		"poi_mine",
		"poi_mine_abandoned",
		"poi_mountain_pass",
		"poi_peak",
		"poi_peak2",
		"poi_place_city",
		"poi_place_hamlet",
		"poi_place_suburb",
		"poi_place_town",
		"poi_place_village",
		"poi_point_of_interest",
		"poi_tower_communications",
		"poi_tower_lookout",
		"poi_tower_power",
		"poi_tower_water",
		"power_station_coal",
		"power_station_gas",
		"power_station_solar",
		"power_station_water",
		"power_station_wind",
		"power_substation",
		"power_tower_high",
		"power_tower_high2",
		"power_tower_low",
		"power_transformer",
		"shopping_alcohol",
		"shopping_bakery",
		"shopping_bicycle",
		"shopping_book",
		"shopping_butcher",
		"shopping_butcher2",
		"shopping_car",
		"shopping_car_repair",
		"shopping_clothes",
		"shopping_computer",
		"shopping_confectionery",
		"shopping_convenience",
		"shopping_copyshop",
		"shopping_department_store",
		"shopping_diy",
		"shopping_estateagent",
		"shopping_estateagent2",
		"shopping_estateagent3",
		"shopping_fish",
		"shopping_florist",
		"shopping_garden_centre",
		"shopping_gift",
		"shopping_greengrocer",
		"shopping_hairdresser",
		"shopping_hearing_aids",
		"shopping_hifi",
		"shopping_jewelry",
		"shopping_jewelry2",
		"shopping_kiosk",
		"shopping_laundrette",
		"shopping_marketplace",
		"shopping_mobile_phone",
		"shopping_motorcycle",
		"shopping_music",
		"shopping_newspaper",
		"shopping_pet",
		"shopping_pet2",
		"shopping_photo",
		"shopping_supermarket",
		"shopping_tackle",
		"shopping_tobacco",
		"shopping_toys",
		"shopping_vending_machine",
		"shopping_video_rental",
		"sport_archery",
		"sport_baseball",
		"sport_canoe",
		"sport_cricket",
		"sport_diving",
		"sport_golf",
		"sport_gym",
		"sport_gymnasium",
		"sport_gymnasium2",
		"sport_hillclimbing",
		"sport_horse_racing",
		"sport_iceskating",
		"sport_jetski",
		"sport_leisure_centre",
		"sport_minature_golf",
		"sport_motorracing",
		"sport_playground",
		"sport_sailing",
		"sport_shooting",
		"sport_skiing_crosscountry",
		"sport_skiing_downhill",
		"sport_snooker",
		"sport_soccer",
		"sport_stadium",
		"sport_swimming_indoor",
		"sport_swimming_outdoor",
		"sport_tennis",
		"sport_windsurfing",
		"tourist_archaeological",
		"tourist_archaeological2",
		"tourist_art_gallery",
		"tourist_art_gallery2",
		"tourist_attraction",
		"tourist_battlefield",
		"tourist_beach",
		"tourist_casino",
		"tourist_castle",
		"tourist_castle2",
		"tourist_cinema",
		"tourist_cinema2",
		"tourist_clock",
		"tourist_fountain",
		"tourist_guidepost",
		"tourist_information",
		"tourist_map",
		"tourist_memorial",
		"tourist_monument",
		"tourist_museum",
		"tourist_night_club",
		"tourist_night_club_gay",
		"tourist_night_club_lesbian",
		"tourist_picnic",
		"tourist_ruin",
		"tourist_steam_train",
		"tourist_theatre",
		"tourist_theme_park",
		"tourist_view_point",
		"tourist_waterwheel",
		"tourist_wayside_cross",
		"tourist_wayside_shrine",
		"tourist_windmill",
		"tourist_wreck",
		"tourist_zoo",
		"transport_aerodrome",
		"transport_aerodrome2",
		"transport_airport",
		"transport_airport2",
		"transport_airport_gate",
		"transport_airport_terminal",
		"transport_bus_station",
		"transport_bus_stop",
		"transport_bus_stop2",
		"transport_car_share",
		"transport_emergency_phone",
		"transport_ford",
		"transport_fuel",
		"transport_fuel_lpg",
		"transport_helicopter",
		"transport_helicopter_pad",
		"transport_lighthouse",
		"transport_marina",
		"transport_miniroundabout_anticlockwise",
		"transport_miniroundabout_clockwise",
		"transport_parking",
		"transport_parking_bicycle",
		"transport_parking_car",
		"transport_parking_car_paid",
		"transport_parking_disabled",
		"transport_parking_private",
		"transport_parking_private2",
		"transport_parking_private3",
		"transport_port",
		"transport_rental_bicycle",
		"transport_rental_car",
		"transport_roundabout_anticlockwise",
		"transport_roundabout_clockwise",
		"transport_slipway",
		"transport_speedbump",
		"transport_subway",
		"transport_taxi_rank",
		"transport_traffic_lights",
		"transport_train_station",
		"transport_train_station2",
		"transport_tram_stop",
		"transport_turning_circle",
		"transport_walking",
		"transport_zebra_crossing",
		"water_dam",
		"water_tower",
		"water_weir"
	],
	"fontawesome": [
		"arrow-left",
		"arrow-right",
		"person-biking",
		"car",
		"chart-line",
		"copy",
		"circle-info",
		"slash",
		"person-walking"
	]
}